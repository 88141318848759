import { useEffect, useState } from 'react';
import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Auth
// import { useAuth0 } from '@auth0/auth0-react';
import { useNeuAuth } from '@neuraptic/neuauth-react';

// Styles

// Roles & permissions management
import { usePermify } from '@permify/react-role';

// Theme
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// Components
import TopMenu from './Components/Core/TopMenu';
import Navigation from './Components/Core/Navigation';
import Footer from './Components/Core/Footer';
import AlertsManager from './Components/Core/AlertsManager';

// Styles
import './App.css';
import { colors } from './consts/colors';

// Pages
import Dashboard from './Pages/Dashboard';
import SignIn from './Pages/SignIn';
import UserSettings from './Pages/Settings/UserSettings';
import OrganizationSettings from './Pages/Settings/OrganizationSettings';
import { Tasks } from './Pages/Tasks';
import { Loader } from './Components/Shared/Loader';
// todo
// import { NotFound } from './Pages/NotFound';
import { CompleteProfile } from './Pages/CompleteProfile';

// HOC
import { ProtectedRoute } from './services/hoc/ProtectedRoute';

// Redux
import {
	/* SET_USER_STATE, */ SET_ACCESS_TOKEN,
	GET_USER,
	GET_USER_PERMISSIONS_AND_ROLES,
	SET_USER_LOCATION,
	GET_USER_SETTINGS,
} from './redux/user.slice';
import {
	GET_ORGANIZATION,
	GET_ORGANIZATION_APPS,
	GET_ORGANIZATION_COLLABORATORS,
	GET_ORGANIZATION_ROLES,
	GET_ORGANIZATION_SUBSCRIPTION,
	GET_ORGANIZATION_USERS,
} from './redux/organization.slice';
import {
	GET_TASK_PERMISSIONS,
	GET_TASK_SETTINGS,
	GET_TASKS,
} from './redux/tasks.slice';
import {
	GET_SCHEMA,
	GET_SCHEMA_NODE_CATEGORIES,
	RESET_ALL_CATEGORIES,
} from './redux/schema.slice';
import { GET_EXAMPLES } from './redux/examples.slice';
import {
	GET_MODELS,
	GET_PRODUCTION_MODEL,
	GET_TESTING_MODEL,
	// GET_PRODUCTION_MODELS
} from './redux/aireleases.slice';

// Services
import { getCookieByName } from './services/extraServices';
import { transcriptPermissions } from './services/transcriptPermissions';
import { SET_LOADING_PROGRESS } from './redux/loaders.slice';
import { useWindowSize } from './services/hooks/useWindowResize';
import MaintenancePage from './Pages/Maintenance';
import { GET_TESTS } from './redux/testing.slice';
import { GET_PREDICTIONS } from './redux/predictions.slice';

const theme = createTheme({
	palette: {
		primary: {
			main: colors.buttonPrimaryColor,
		},
	},
});

function App() {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const windowSize = useWindowSize();
	const { setUser: setUserAccess } = usePermify();

	// const { logout, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
	const { isAuthenticated, logout } = useNeuAuth();

	const { accessToken } = useSelector((state) => state.user);

	const { appIsLoading } = useSelector((state) => state.loaders);

	const { user: userState, organization: organizationState } = useSelector(
		(state) => state
	);
	const {
		currentTask: currentTaskState,
		tasks: tasksState,
		// currentTaskPermissions: currentTaskPermissionsState,
	} = useSelector((state) => state.tasks);
	const { schema: schemaState, categories: categoriesState } = useSelector(
		(state) => state.schema
	);
	const { examples: examplesState } = useSelector((state) => state.examples);

	// todo: review if is necessary
	const [isPermifySettedUp, setIsPermifySettedUp] = useState(false);
	const [isTopMenu, setIsTopMenu] = useState(false);
	const [currentLocation, setCurrentLocation] = useState('');

	useEffect(() => {
		if (window.localStorage.getItem('__permifyUser') !== '')
			setIsPermifySettedUp(true);
	}, [window.localStorage.getItem('__permifyUser')]);

	useEffect(() => {
		dispatch(
			SET_USER_LOCATION(Intl.DateTimeFormat().resolvedOptions().timeZone)
		);
		const token = getCookieByName('neu.auth.accessToken');
		if (token !== null) dispatch(SET_ACCESS_TOKEN(token));
		if (!localStorage.getItem('oldTaskId')) {
			localStorage.setItem('oldTaskId', '');
		}
		if (
			!isAuthenticated() &&
			currentLocation !== 'dashboard' &&
			currentLocation !== 'signin' &&
			location.pathname !== '/oauth2/callback'
		)
			navigate('/');
	}, []);

	useEffect(() => {
		setCurrentLocation(location.pathname.split('/').slice(1)[0]);
	}, [location.pathname]);

	const getUserAndOrganization = async () => {
		await dispatch(GET_USER({ accessToken, dispatch, navigate }));
		await dispatch(GET_USER_SETTINGS({ accessToken, dispatch }));
		await dispatch(GET_ORGANIZATION({ accessToken, dispatch, navigate }));
	};

	useEffect(() => {
		if (isAuthenticated() && accessToken) {
			getUserAndOrganization();
			if (!currentTaskState.uuid)
				dispatch(SET_LOADING_PROGRESS({ progress: 0, buffer: 0 }));
		}
	}, [isAuthenticated(), accessToken]);

	useEffect(() => {
		if (
			organizationState &&
			organizationState.info &&
			organizationState.info.id !== undefined &&
			isAuthenticated() &&
			isPermifySettedUp
		) {
			if (tasksState.length === 0 && userState && userState.id)
				dispatch(GET_TASKS({ accessToken, dispatch }));
			if (userState.id) {
				dispatch(
					GET_USER_PERMISSIONS_AND_ROLES({
						accessToken,
						organizationId: organizationState.info.id,
						userId: userState.id,
						dispatch,
					})
				);
			}
			if (currentLocation === 'settings') {
				dispatch(
					GET_ORGANIZATION_USERS({
						organizationId: organizationState.info.id,
						accessToken,
						dispatch,
					})
				);
				dispatch(
					GET_ORGANIZATION_SUBSCRIPTION({
						organizationId: organizationState.info.id,
						accessToken,
						dispatch,
					})
				);
				dispatch(
					GET_ORGANIZATION_COLLABORATORS({
						organizationId: organizationState.info.id,
						accessToken,
						dispatch,
					})
				);
				dispatch(
					GET_ORGANIZATION_ROLES({
						organizationId: organizationState.info.id,
						accessToken,
						dispatch,
					})
				);
				dispatch(
					GET_ORGANIZATION_APPS({
						organizationId: organizationState.info.id,
						accessToken,
						dispatch,
					})
				);
			}
		}
	}, [
		organizationState && organizationState.info && organizationState.info.id,
		userState && userState.id,
		currentLocation,
	]);

	const getCurrentTask = async () => {
		await dispatch(SET_LOADING_PROGRESS({ progress: 0, buffer: 30 }));
		// SCHEMA REQUESTS
		await dispatch(
			GET_SCHEMA({
				taskId: currentTaskState.uuid || localStorage.getItem('oldTaskId'),
				accessToken,
				dispatch,
			})
		);
		await dispatch(SET_LOADING_PROGRESS({ progress: 20, buffer: 30 }));
		await dispatch(SET_LOADING_PROGRESS({ progress: 30, buffer: 50 }));
		// EXAMPLES REQUESTS
		await dispatch(
			GET_EXAMPLES({
				taskId: currentTaskState.uuid || localStorage.getItem('oldTaskId'),
				accessToken,
				dispatch,
			})
		);
		await dispatch(SET_LOADING_PROGRESS({ progress: 40, buffer: 60 }));
		// AI RELEASES REQUESTS
		await dispatch(
			GET_MODELS({
				taskId: currentTaskState.uuid || localStorage.getItem('oldTaskId'),
				accessToken,
				dispatch,
			})
		);
		await dispatch(
			GET_TESTING_MODEL({
				taskId: currentTaskState.uuid || localStorage.getItem('oldTaskId'),
				accessToken,
				dispatch,
			})
		);
		await dispatch(
			GET_PRODUCTION_MODEL({
				taskId: currentTaskState.uuid || localStorage.getItem('oldTaskId'),
				accessToken,
				dispatch,
			})
		);

		await dispatch(SET_LOADING_PROGRESS({ progress: 60, buffer: 70 }));
		// TESTING REQUESTS
		await dispatch(
			GET_TESTS({
				taskId: currentTaskState.uuid || localStorage.getItem('oldTaskId'),
				accessToken,
				dispatch,
				environment: 'testing',
			})
		);
		// PREDICTION REQUESTS
		await dispatch(
			GET_PREDICTIONS({
				taskId: currentTaskState.uuid || localStorage.getItem('oldTaskId'),
				accessToken,
				dispatch,
				environment: 'production',
			})
		);
		await dispatch(SET_LOADING_PROGRESS({ progress: 70, buffer: 90 }));
		// SETTINGS
		await dispatch(
			GET_TASK_SETTINGS({
				taskId: currentTaskState.uuid || localStorage.getItem('oldTaskId'),
				accessToken,
				dispatch,
			})
		);
		await dispatch(SET_LOADING_PROGRESS({ progress: 90, buffer: 100 }));
		await dispatch(
			GET_TASK_PERMISSIONS({
				taskId: currentTaskState.uuid || localStorage.getItem('oldTaskId'),
				accessToken,
				dispatch,
			})
		);
		await dispatch(SET_LOADING_PROGRESS({ progress: 100, buffer: 100 }));
		if (currentTaskState.uuid)
			localStorage.setItem('oldTaskId', currentTaskState.uuid);
	};

	useEffect(() => {
		dispatch(SET_LOADING_PROGRESS({ progress: 0, buffer: 30 }));
		if (
			accessToken !== '' &&
			userState.id !== '' &&
			isAuthenticated() &&
			((currentTaskState &&
				currentTaskState.uuid &&
				localStorage.getItem('currentTaskId') !==
					localStorage.getItem('oldTaskId')) ||
				examplesState.length === 0)
		) {
			getCurrentTask();
			dispatch(RESET_ALL_CATEGORIES());
		}
	}, [currentTaskState.uuid]);

	const getCategories = async () => {
		if (Object.keys(categoriesState).length === 0) {
			// INPUTS
			if (schemaState.inputs)
				schemaState.inputs.forEach(async (input) => {
					if (input.type === 'category') {
						await dispatch(
							GET_SCHEMA_NODE_CATEGORIES({
								accessToken,
								taskId: currentTaskState.uuid,
								dispatch,
								nodeType: 'inputs',
								nodeId: input.id,
								nodeName: input.name,
							})
						);
					}
				});

			// METADATA
			if (schemaState.metadata)
				schemaState.metadata.forEach(async (meta) => {
					if (meta.type === 'category') {
						await dispatch(
							GET_SCHEMA_NODE_CATEGORIES({
								accessToken,
								taskId: currentTaskState.uuid,
								dispatch,
								nodeType: 'metadata',
								nodeId: meta.id,
								nodeName: meta.name,
							})
						);
					}
				});

			// OUTPUTS
			if (schemaState.outputs)
				schemaState.outputs.forEach(async (output) => {
					if (output.type === 'category') {
						await dispatch(
							GET_SCHEMA_NODE_CATEGORIES({
								accessToken,
								taskId: currentTaskState.uuid,
								dispatch,
								nodeType: 'outputs',
								nodeId: output.id,
								nodeName: output.name,
							})
						);
					}
				});
		}
	};

	useEffect(() => {
		if (schemaState && Object.keys(schemaState).length > 0) {
			getCategories();
		}
	}, [schemaState]);

	useEffect(() => {
		const tmpRoles = () => {
			const tmp = [];
			if (userState.roles.some((role) => role.name === 'admin'))
				tmp.push('admin');
			if (userState.roles.some((role) => role.name === 'maintainer'))
				tmp.push('maintainer');
			return tmp;
		};
		if (
			organizationState.info &&
			organizationState.info.id &&
			userState.id !== null &&
			(userState.permissions.length > 0 || userState.roles.length > 0)
		) {
			setUserAccess({
				id: userState.id,
				roles: tmpRoles(),
				permissions: transcriptPermissions({
					permissions: userState.permissions,
					organizationId: organizationState.info.id,
				}),
			});
		}
	}, [userState, userState.permissions, userState.roles, organizationState]);

	useEffect(() => {
		document.getElementById('root').style.marginTop = '0vh';
		if (isTopMenu) {
			setIsTopMenu(true);
			if (windowSize.width < 900 && windowSize.width > 600)
				document.getElementById('root').style.marginTop = '12vh';
			else if (windowSize.width < 600)
				document.getElementById('root').style.marginTop = '14vh';
			else document.getElementById('root').style.marginTop = '9vh';
		}
	}, [isTopMenu, windowSize]);

	useEffect(() => {
		if (userState && userState.termsAgreed !== null && !userState.termsAgreed) {
			navigate('/complete-profile');
		}
	}, [userState, organizationState]);

	const handleSignOut = () => {
		window.localStorage.setItem('access_token', '');
		dispatch(SET_ACCESS_TOKEN(''));
		setIsTopMenu(false);
		logout();
	};

	if (process.env.REACT_APP_IS_MAINTENANCE_MODE === 'true') {
		return (
			<Routes>
				<Route exact path="/" element={<MaintenancePage />} />
			</Routes>
		);
	}

	return (
		<ThemeProvider theme={theme}>
			{appIsLoading ? (
				<Loader size="L" />
			) : (
				<>
					{
						// todo: complete profile
					}
					{/* {userState.isAuthenticated && !isCompleteProfilePage && ( */}
					{
						// todo: change for isAuthenticated()
					}
					{isAuthenticated() && currentLocation !== 'complete-profile' && (
						<>
							<TopMenu
								handleSignOut={handleSignOut}
								setIsTopMenu={setIsTopMenu}
							/>
							<Navigation />
						</>
					)}
					<AlertsManager />
					<CssBaseline />
					<Routes>
						<Route
							exact
							path="*"
							element={
								isAuthenticated() ? (
									<Navigate replace to="/dashboard" />
								) : (
									<Navigate replace to="/signin" />
								)
							}
						/>
						<Route exact path="/signin" element={<SignIn />} />
						<Route
							exact
							path="/complete-profile"
							element={<CompleteProfile setIsTopMenu={setIsTopMenu} />}
						/>
						<Route element={<ProtectedRoute />}>
							<Route
								exact
								path="/"
								element={<Navigate replace to="/dashboard" />}
							/>
							<Route exact path="/dashboard" element={<Dashboard />} />
							<Route path="/tasks/:id" element={<Tasks />} />
							<Route path="/settings">
								<Route index path="" element={<Navigate to="/" />} />
								<Route path="user" element={<UserSettings />} />
								<Route path="organization" element={<OrganizationSettings />} />
							</Route>
						</Route>
					</Routes>
					{
						// todo: complete profile
					}
					<Footer />
				</>
			)}
		</ThemeProvider>
	);
}

export default App;
