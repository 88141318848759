import { ADD_ALERT } from '../redux/alerts.slice';

// REQUEST FACTORY FUNCTION
const requestFactory = async ({ type, url, accessToken, data, dispatch }) => {
	let res = await fetch(`${process.env.REACT_APP_ENAIA_API_URL}${url}`, {
		method: type,
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'content-type': 'application/json',
			cors: 'no-cors',
		},
		body: data && JSON.stringify(data),
	});

	let json = null;

	if (res) {
		json = await res.json();
	}

	// Handle 400 and 500 errors
	let statusError = false;
	if (res.status[0] === 4 || res.status[0] === 5) statusError = true;

	if (statusError && type === 'GET') {
		res = await fetch(`${process.env.REACT_APP_ENAIA_API_URL}${url}`, {
			method: type,
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'content-type': 'application/json',
				cors: 'no-cors',
			},
			body: data && JSON.stringify(data),
		});
	}

	if (statusError) {
		if (json.error) {
			dispatch(ADD_ALERT({ type: 'error', message: json.error.message }));
		} else if (json.errors) {
			Object.keys(json.errors.json).forEach((error) => {
				dispatch(
					ADD_ALERT({ type: 'error', message: json.errors.json[error][0] })
				);
			});
		} else
			dispatch(
				ADD_ALERT({
					type: 'error',
					message:
						'An unexpected error has occurred. Contact contact@neuraptic.ai for support.',
				})
			);
		return;
	}

	if (res && !json.error) {
		return json;
	}

	return undefined;
};

export default requestFactory;
